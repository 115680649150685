/* eslint-disable import/prefer-default-export */
export const BASE_URL = 'https://portal.ibs.ke/api';
// export const BASE_URL = "https://staging.ibs.ke/api";
// export const BASE_URL = "http://localhost:8080/api";

const hosts = window.location.host.split('.');

export const LAYOUT_CONFIGS = {
  default: {
    showSidebar: true,
    primaryColor: '#149FCB',
    companyId: 1,
    source: "Vehicle.co.ke"
  },
  'digger-motors': {
    showSidebar: false,
    primaryColor: '#dd4943',
    companyId: 2,
    source: "DIGGER"
  },
  'cheki-insure': {
    showSidebar: false,
    primaryColor: '#008f4a',
    companyId: 3,
    source: "CHECKI INSURE"
  },
  'amokevlogs': {
    showSidebar: false,
    primaryColor: '#ED2227',
    companyId: 4,
    source: "AMOKEVLOGS"
  },
  'shesmercedes': {
    showSidebar: false,
    primaryColor: '#FF7900',
    companyId: 10,
    source: "SHE'S MERCEDES"
  },
};


export const LAYOUT_CONFIG = LAYOUT_CONFIGS[hosts[0]] || LAYOUT_CONFIGS.default;
