import { createMuiTheme } from "@material-ui/core";
import kebabCase from "lodash/kebabCase";
import find from "lodash/find";
import split from "lodash/split";
import isEmpty from "lodash/isEmpty";
import defaultTheme from "./default";

const palettes = [
  {
    name: "aig",
    palette: {
      primary: {
        main: "#1BA3DD",
      },
    },
  },
  {
    name: "allianz",
    palette: {
      primary: {
        main: "#003781",
      },
    },
  },
  {
    name: "amaco",
    palette: {
      primary: {
        main: "#EC278F",
      },
      secondary: {
        main: "#000000",
      },
    },
  },
  {
    name: "apa",
    palette: {
      primary: {
        main: "#034EA2",
      },
      secondary: {
        main: "#EE2527",
      },
    },
  },
  {
    name: "britam",
    palette: {
      primary: {
        main: "#007DC3",
      },
      secondary: {
        main: "#EE3124",
      },
    },
  },
  {
    name: "cic",
    palette: {
      primary: {
        main: "#AA1F2B",
      },
      secondary: {
        main: "#F9B80A",
      },
    },
  },
  {
    name: "corporate",
    palette: {
      primary: {
        main: "#DA0B47",
      },
    },
  },
  {
    name: "directline",
    palette: {
      primary: {
        main: "#FFEE00",
      },
      secondary: {
        main: "#000000",
      },
    },
  },
  {
    name: "Fidelity",
    palette: {
      primary: {
        main: "#027B7C",
      },
      secondary: {
        main: "#B6DC77",
      },
    },
  },
  {
    name: "First Assurance",
    palette: {
      primary: {
        main: "#919396",
      },
      secondary: {
        main: "#FF661C",
      },
    },
  },
  {
    name: "sanlam",
    palette: {
      primary: {
        main: "#0076C8",
      },
    },
  },

  {
    name: "heritage",
    palette: {
      primary: {
        main: "#123748",
      },
    },
  },
];
const overrides = {
  typography: {
    fontFamily: "'Quicksand', sans-serif",
    h1: {
      fontSize: "3rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h2: {
      fontSize: "2rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h3: {
      fontSize: "1.64rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h5: {
      fontSize: "1.285rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    h6: {
      fontSize: "1.142rem",
      fontFamily: "'Quicksand', sans-serif",
    },
    subtitle2: {
      fontFamily: "'Quicksand', sans-serif",
    },
    subtitle1: {
      fontFamily: "'Quicksand', sans-serif",
    },
    caption: {
      fontFamily: "'Quicksand', sans-serif",
    },
    button: {
      fontFamily: "'Quicksand', sans-serif",
    },
    body1: {
      fontFamily: "'Quicksand', sans-serif",
    },
    body2: {
      fontFamily: "'Quicksand', sans-serif",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          fontFamily: "Lato, sans-serif",
          fontSize: 14,
          lineHeight: "24px",
        },
      },
    },
  },
};
const SetupTheme = () => {
  const location = window.location.pathname;
  const pathName = split(location, "/").pop();
  if (isEmpty(pathName))
    return createMuiTheme({ ...defaultTheme, ...overrides });
  const getTheme = find(
    palettes,
    (value) =>
      kebabCase(value.name.toLowerCase()).search(
        kebabCase(pathName.toLowerCase())
      ) > -1 || value.name
      .replace(/\s/g, "")
      .toLowerCase()
      .search(pathName.toLowerCase()) > -1
  );
  if (isEmpty(getTheme))
    return createMuiTheme({ ...defaultTheme, ...overrides });
  return createMuiTheme({
    ...{
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        ...getTheme.palette,
      },
    },
    ...overrides,
  });
};
export default SetupTheme;
