const INITIAL_STATE = {
  queue: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "notification/SET_STATE":
      return { ...state, ...action.payload };
    case "SHOW_NOTIFICATION":
      return { queue: [...state.queue, { id: Date.now(), ...action.payload }] };
    case "DISMISS_NOTIFICATION":
      return {
        queue: state.queue.filter(
          (notification) => notification.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};
