import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LeftSidebar from '../../shared/comman/left-sidebar';
import Footer from '../../shared/comman/footer';
import {
  fetchRiskClasses,
  fetchVehicleMakes,
} from '../../shared/actions/quote';
import useStyles from './styles';
import useConfig from '../../hooks/useConfig';

const PublicLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const config = useConfig();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    dispatch(fetchRiskClasses());
    dispatch(fetchVehicleMakes());
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {config.showSidebar && <LeftSidebar />}
        <main className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
          {!mobileScreen && (
            <div className={classes.bottomContainer}>
              <div className={classes.bottomContent}>
                <Typography component="p" className={classes.bottomText}>
                  CONTACT CENTER: +254 709 783 000
                </Typography>
              </div>
            </div>
          )}
        </main>
        {mobileScreen && <Footer />}
      </div>
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

PublicLayout.defaultProps = {};

export default memo(PublicLayout);
