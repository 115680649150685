import React from "react";
import { Link } from 'react-router-dom';
import { Typography, Button } from "@material-ui/core";
import useStyles from "./styles";

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography component="h1" variant="h1" className={classes.primary}>
        404
      </Typography>
      <Typography component="h2" variant="h2" className={classes.secondary}>
        UH OH! You're lost.
      </Typography>
      <Typography className={classes.paragraph}>
        The page you are looking for does not exist. How you got here is a
        mystery. But you can click the button below to go back to the homepage.
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary" className={classes.button}>Home</Button>
    </div>
  );
};

export default PageNotFound;
