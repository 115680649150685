import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { Helmet } from "react-helmet";
import { Typography } from "@material-ui/core";
import { AppIcon } from "../index";
import { SanlamHeaderLogo, HeritageHeaderLogo, FirstAssuranceHeaderLogo, BritamHeaderLogo, ICEAHeaderLogo } from "./header-logo";
import useStyles from "./styles";

const underwriters = [
    {
        name: "heritage",
        logo: <HeritageHeaderLogo height="100%" width="100%" style={{ maxWidth:'300px'}} />,
    },
    {
        name: "sanlam",
        logo: <SanlamHeaderLogo height="100%" width="100%" style={{ maxWidth:'300px'}} />,
    },
    {
        name: "firstassurance",
        logo: <FirstAssuranceHeaderLogo height="100%" width="100%" style={{ maxWidth:'300px'}} />,
    },
    {
        name: "britam",
        logo: <BritamHeaderLogo height="100%" width="60%" style={{ maxWidth: '300px' }} />,
    },
    {
        name: "icealion",
        logo: <ICEAHeaderLogo height="100%" width="70%" style={{ maxWidth: '300px' }} />,
    },
];

const DefaultIcon = () => {
  const classes = useStyles();
  return (
    <>
      <AppIcon className={classes.brandIcon} />
      <Typography variant="h4" component="h4" className={classes.brandText}>
        Vehicle
      </Typography>
    </>
  );
};

const BrandIcon = ({ name }) => {
  if (isEmpty(name)) return <DefaultIcon />;
  const getUnderwriter = find(
    underwriters,
    (value) =>
      kebabCase(value.name.toLowerCase()).search(
        kebabCase(name.toLowerCase())
      ) > -1
  );
  if (isEmpty(getUnderwriter)) return <DefaultIcon />;
  const BootstrappedLayout = () => {
    return (
      <>
        <Helmet
          title={`${capitalize(getUnderwriter.name)} - Motor Insurance`}
        />
        {getUnderwriter.logo}
      </>
    );
  };
  return BootstrappedLayout();
};

BrandIcon.propTypes = {
  name: PropTypes.string,
};
BrandIcon.defaultProps = {
  name: "",
};
export default BrandIcon;
