import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  getDirectLinkQuote,
  selectedStep,
} from '../../../shared/actions/quote';
import { lazy } from 'react';
import { useLocation } from 'react-router-dom';
const QuoteContainer = lazy(() => import('../container'));

const DirectLink = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(selectedStep(3));
    const searchParams = new URLSearchParams(location.search);
    dispatch(getDirectLinkQuote(searchParams.get('quoteid')));
  }, []);

  return <QuoteContainer {...props} />;
};
DirectLink.propTypes = {};

export default DirectLink;
