const addNotification = (payload) => ({
  type: "SHOW_NOTIFICATION",
  payload,
});

const removeNotification = (payload) => ({
  type: "DISMISS_NOTIFICATION",
  payload,
});

export { addNotification, removeNotification };
