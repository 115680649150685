const initialState = {
    loading: false,
    updating: false,
    optedIn: false,
    showProfile: false,
    showAccount: false
};

export default function kycReducer(state = initialState, action) {
    switch (action.type) {
        case "SHOW_PROFILE":
            return {
                ...state,
                showProfile: action.payload.showProfile,
            };

        default:
            return state;
    }

}