import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#fff",
    height: 59,
    marginTop: theme.spacing(3)
  },
  content: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: 59,
  },
  primaryText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    fontSize: 10,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1.2),
  },
  brandIcon: {
    fontSize: 18,
    color: theme.palette.primary.main
  }
}));
