import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageLoader from '../shared/comman/page-loader';
import ErrorBoundaryRoute from '../shared/errors/ErrorBoundaryRoute';
import NotFoundPage from '../shared/errors/PageNotFound';
import IndexLayout from '../layouts';
import DirectLink from '../modules/quote/direct-link';

const QuoteContainer = lazy(() => import('../modules/quote/container'));
const KYCContainer = lazy(() => import('../modules/kyc/container'));


const appRoutes = [
  {
    path: '/',
    component: QuoteContainer,
    exact: true,
  },
  {
    path: '/direct-link',
    component: DirectLink,
    exact: true,
  },
  {
    path: '/:underwriter',
    component: QuoteContainer,
    exact: true,
  },
  {
    path: '/get-quote',
    component: QuoteContainer,
    exact: true,
  },
  {
    path: '/kyc-upload/:id/:mobile',
    component: KYCContainer,
    exact: true,
  },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <IndexLayout>
          <Switch>
            {appRoutes.map((route) => (
              <ErrorBoundaryRoute
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </Suspense>
    </BrowserRouter>
  );
};

Routes.propTypes = {};

Routes.defaultProps = {};

export default React.memo(Routes);
