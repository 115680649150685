const initialState = {
    loading: false,
    optedIn: false,
    showProfile: false,
    showAccount: false

};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case "SHOW_PROFILE":
            return {
                ...state,
                showProfile: action.payload.showProfile,
            };
        case "SHOW_ACCOUNT":
            return {
                ...state,
                client: action.payload.data,
                showAccount: action.payload.showAccount,
            };
        case "IPF_IDENTITY_SUCCESS":
            return {
                ...state,
                optedIn: true,
            };
        case "IPF_IDENTITY_FAILURE":
            return {
                ...state,
            };
        case "IPF_OPT_OUT_SUCCESS":
            return {
                ...state,
                optedIn: false,
            };

        default:
            return state;
        }
    }