import { startSubmit, stopSubmit } from 'redux-form';
import kebabCase from 'lodash/kebabCase';
import find from 'lodash/find';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
// import { el } from 'date-fns/locale';
import { addNotification } from '../notification';
import {
  getRiskClasses,
  getCoverTypes,
  getVehicleTypes,
  getVehicleModels,
  getVehicleMakes,
  getTonnageRanges,
  getAllUnderwriters,
  getUnderwriter,
  getAdditionalBenefits,
  createQuote,
  startQuote,
  updateQuote,
  requestPayment,
  buyNowTrackQuote,
  welcomeSendSms,
  oneQuotePublicLink,
  getQuote,
} from '../../../services/quote';

export const setCarDetailsValues = (payload) => ({
  type: 'SET_CAR_DETAILS_VALUES',
  payload,
});

export const setAdditionalValues = (payload) => ({
  type: 'SET_ADDITIONAL_VALUES',
  payload,
});

export const selectedVehicle = (payload) => ({
  type: 'SELECT_VEHICLE_TYPE',
  payload,
});

export const selectRiskClass = (payload) => ({
  type: 'SELECT_RISK_CLASS',
  payload,
});

export const selectedStep = (payload) => ({
  type: 'SELECT_STEP',
  payload,
});

export const selectAddBenefit = (payload) => ({
  type: 'SELECT_ADD_BENEFIT',
  payload,
});

export const selectBenefitQuote = (payload) => ({
  type: 'SELECT_BENEFIT_QUOTE',
  payload,
});

// buy-now track
export const buyNowTrack = (values) => {
  return async (dispatch) => {
    // dispatch({
    //   type: "FETCH_QUOTES_LOADING",
    // });
    const response = await buyNowTrackQuote(values);
    if (response.success) {
      // dispatch({
      //   type: "UPDATE_QUOTE_SUCCESS",
      //   payload: response.data,

      // },);
    }
  };
};

export const selectedAdditionalBenefit = (payload) => ({
  type: 'SELECTED_ADDITIONAL_BENEFITS',
  payload,
});

export const flushAddBenefit = () => ({
  type: 'FLUSH_ADD_BENEFIT',
});

export const addToCartItem = (payload) => ({
  type: 'ADD_TO_CART_ITEM',
  payload,
});

export const setAgentCode = (payload) => ({
  type: 'SET_AGENT_CODE',
  payload,
});

export const setUnderwriterParams = (payload) => ({
  type: 'SET_UNDERWRITER_PARAMS',
  payload,
});

export const fetchRiskClasses = (queryParam) => {
  return async (dispatch) => {
    const response = await getRiskClasses(queryParam);
    if (response.success) {
      dispatch({
        type: 'FETCH_RISK_CLASSES_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const fetchCoverTypes = (selectedRiskClassID) => {
  return async (dispatch) => {
    const response = await getCoverTypes(selectedRiskClassID);
    if (response.success) {
      dispatch({
        type: 'FETCH_COVER_TYPES_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const fetchVehicleModels = (selectedMakeID) => {
  return async (dispatch) => {
    const response = await getVehicleModels(selectedMakeID);
    if (response.success) {
      dispatch({
        type: 'FETCH_VEHICLE_MODELS_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const fetchVehicleMakes = () => {
  return async (dispatch) => {
    const response = await getVehicleMakes();
    if (response.success) {
      dispatch({
        type: 'FETCH_VEHICLE_MAKES_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const fetchVehicleTypes = (categoryName) => {
  return async (dispatch) => {
    const response = await getVehicleTypes(categoryName);
    if (response.success) {
      dispatch({
        type: 'FETCH_VEHICLE_TYPES_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const fetchTonnageRanges = () => {
  return async (dispatch) => {
    const response = await getTonnageRanges();
    if (response.success) {
      dispatch({
        type: 'FETCH_TONNAGE_RANGES_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const fetchAdditionalBenefits = (selectedQuoteID) => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_ADDITIONAL_BENEFITS_LOADING',
    });
    const response = await getAdditionalBenefits(selectedQuoteID);
    if (response.success) {
      dispatch({
        type: 'FETCH_ADDITIONAL_BENEFITS_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'FETCH_ADDITIONAL_BENEFITS_FAILURE',
        payload: [],
      });
    }
  };
};

// One Quote - Public Link
export const quoteLink = (selectedQuoteID) => {
  return async (dispatch) => {
    const response = await oneQuotePublicLink(selectedQuoteID);
    if (response.success) {
      console.log('success', response);
    }
  };
};

export const fetchUnderwriter = (underwriterId) => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_UNDERWRITER_LOADING',
    });
    const response = await getUnderwriter(underwriterId);
    if (response.success) {
      dispatch({
        type: 'FETCH_UNDERWRITER_SUCCESS',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'FETCH_UNDERWRITER_FAILURE',
        payload: {}
      });
    }
  };
};


export const fetchAllUnderwriter = (selectedUnderwriter) => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_ALL_UNDERWRITER_LOADING',
    });
    const response = await getAllUnderwriters();
    if (response.success) {
      const getSelectedUnderwriter = find(response.data, (value) => {
        return (
          kebabCase(value.name.toLowerCase()).search(
            kebabCase(selectedUnderwriter.toLowerCase()),
          ) > -1 ||
          value.name
            .replace(/\s/g, '')
            .toLowerCase()
            .search(selectedUnderwriter.toLowerCase()) > -1
        );
      });
      dispatch({
        type: 'FETCH_ALL_UNDERWRITER_SUCCESS',
        payload: getSelectedUnderwriter || {},
      });
    } else {
      dispatch({
        type: 'FETCH_ALL_UNDERWRITER_FAILURE',
        payload: [],
      });
    }
  };
};

export const startGetQuote = (values, moveToStep = 2) => {
  return async (dispatch) => {
    dispatch({
      type: 'START_QUOTE_LOADING',
    });
    const response = await startQuote(values);
    if (response.success) {
      dispatch({
        type: 'START_QUOTE_SUCCESS',
        payload: {
          data: response.data,
        },
      });
      // if (response.data.client) {
      //   dispatch({
      //     type: 'SHOW_PROFILE',
      //     payload: {
      //       data: response.data,
      //       showProfile: true
      //     },
      //   });
      // }
      dispatch(selectedStep(moveToStep));
      // if (has(values, 'underwriterId')) {
      //   dispatch(selectBenefitQuote({ show: true, quote: filterData[0] }));
      // }
    } else {
      dispatch({
        type: 'START_QUOTE_FAILURE',
      });
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
  };
};

export const createNewQuote = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_QUOTES_LOADING',
    });
    dispatch(startSubmit('vehicleForm'));
    const response = await createQuote(values);
    if (response.success) {
      const filterData = filter(response.data, (q) => q.premiumPayable);
      const quoteValue = find(response.data, (q) => !q.underwriterId);
      // dispatch(selectedStep(3));
      dispatch({
        type: 'FETCH_QUOTES_SUCCESS',
        payload: {
          data: response.data,
          quote: quoteValue,
          formValues: values,
        },
      });
      // if (has(values, 'underwriterId')) {
      //   dispatch(selectBenefitQuote({ show: true, quote: filterData[0] }));
      // }
    } else {
      dispatch({
        type: 'FETCH_QUOTES_FAILURE',
      });
      dispatch(
        addNotification({
          message: response.data.message,
          variant: 'error',
        }),
      );
    }
    dispatch(stopSubmit('vehicleForm'));
  };
};

export const getDirectLinkQuote = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_QUOTES_LOADING',
    });
    dispatch(startSubmit('vehicleForm'));
    const response = await getQuote(data);
    dispatch(
      setCarDetailsValues({
        mobile: response.data.mobile.replace(/^0+/, '254'),
      }),
    );

    if (response.data.riskClassId) {
      dispatch(fetchCoverTypes(response.data.riskClassId));
    }
    if (response.data.vehicleTypeId) {
      dispatch(fetchVehicleTypes(response.data.vehicleTypeId));
      if (response.data.vehicleTypeId === 'COMMERCIAL_PRIMARY') {
        dispatch(fetchTonnageRanges());
      }
    }
    dispatch(
      createNewQuote(
        pick(response.data, [
          'vehicleTypeId',
          'coverTypeId',
          'mobile',
          'riskClassId',
          'email',
          'sumInsured',
          'registration',
        ]),
      ),
    );
  };
};

// Welcome Send SMS Action
export const welcomeSmsAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_QUOTES_LOADING',
    });
    const response = await welcomeSendSms(values);
    if (response.success) {
      dispatch({
        type: 'UPDATE_QUOTE_SUCCESS',
        payload: response.data,
      });
    }
  };
};

export const updateNewQuote = (values, moveToStep = 4) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_QUOTE_LOADING',
    });
    const response = await updateQuote(values);
    if (response.success) {
      dispatch({
        type: 'UPDATE_QUOTE_SUCCESS',
        payload: response.data,
      });
      dispatch(selectedStep(moveToStep));
      dispatch(addToCartItem(values));
    } else {
      dispatch({
        type: 'UPDATE_QUOTE_FAILURE',
      });
      dispatch(selectedStep(moveToStep));
      dispatch(addToCartItem(values));
      dispatch(
        addNotification({
          message: response.data.title,
          variant: 'error',
        }),
      );
    }
  };
};

export const makePaymentRequest = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'MAKE_PAYMENT_LOADING',
    });
    const response = await requestPayment(values);
    if (response.success) {
      if (response.data.transactionStatus === 'FAILED') {
        dispatch({
          type: 'MAKE_PAYMENT_FAILURE',
          payload: {
            message:
              'Your payment has failed! Please try again or contact us on +254 709 783 000',
          },
        });
      } else {
        dispatch({
          type: 'MAKE_PAYMENT_SUCCESS',
          payload: response.data,
        });
      }
    } else {
      dispatch({
        type: 'MAKE_PAYMENT_FAILURE',
        payload: {
          message: response.data.title,
        },
      });
    }
  };
};
