import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  brandIcon: {
    fontSize: 65,
    color: theme.palette.common.white,
  },
  brandText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    fontSize: 50,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    marginLeft: theme.spacing(4),
  },
}));
