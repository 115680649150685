import React from "react";
import elephant from "../../../assets/images/elephant.png";
import giraffe from "../../../assets/images/giraffe.png";
import middleImage from "../../../assets/images/group_179.png";

import useStyles from "./styles";

const BottomBackground = () => {
  const classes = useStyles();
  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.backgroundContent}>
        <img src={giraffe} alt="giraffe" className={classes.backgroundImage} />
        <img
          src={middleImage}
          alt="middleImage"
          className={classes.middleBackgroundImage}
        />
        <img
          src={elephant}
          alt="elephant"
          className={classes.lastBackgroundImage}
        />
      </div>
    </div>
  );
};

BottomBackground.propTypes = {};

export default BottomBackground;
