import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  middleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
  },
  middleContent: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 'calc(100% - 100px)'
  },
  absolute: {
    position: "absolute",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: "100%",
    minHeight: "100%",
  },
  textContainer: {
    margin: theme.spacing(3, 0),
  },
  brandPrimary: {
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.common.white,
    fontFamily: "'Quicksand', sans-serif",
  }
}));
