import React from "react";
import { Typography } from "@material-ui/core";
import { AppIcon } from "../../../assets/svgs";
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.container}>
      <div className={classes.content}>
        <Typography className={classes.primaryText}>POWERED BY</Typography>
        <AppIcon className={classes.brandIcon} />
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
