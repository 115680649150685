import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import map from "lodash/map";
import filter from 'lodash/filter';

const initialState = {
  loading: false,
  updating: false,
  benefitLoading: false,
  paymentLoading: false,
  paymentError: false,
  paymentMessage: null,
  underwriterName: null,
  selectedVehicle: "PRIVATE",
  selectedStep: 1,
  selectedRiskClass: {},
  selectedUnderwriter: {},
  showAddBenefit: false,
  carDetailsValues: {},
  additionalValues: {},
  additionalBenefits: [],
  selectedAdditionalBenefits: [],
  selectVehicleValues: {},
  riskClasses: [],
  coverTypes: [],
  vehicleTypes: [],
  vehicleMakes: [],
  vehicleModels: [],
  tonnageRanges: [],
  quotes: [],
  underwriter: {},
  quote: {},
  cart: {},
  cartTotal: 0,
  payment: {},
};

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECT_VEHICLE_TYPE":
      return {
        ...state,
        selectedVehicle: action.payload,
      };
    case "SELECT_RISK_CLASS":
      return {
        ...state,
        selectedRiskClass: action.payload
      };
    case "SELECT_STEP":
      return {
        ...state,
        showAddBenefit: false,
        selectedStep: action.payload,
      };
    case "SELECT_ADD_BENEFIT": {
      return {
        ...state,
        showAddBenefit: action.payload,
      };
    }
    case "SELECT_BENEFIT_QUOTE": {
      return {
        ...state,
        showAddBenefit: action.payload.show,
        selectedAdditionalBenefits: [],
        quote: action.payload.quote,
      };
    }
    case "FLUSH_ADD_BENEFIT":
      return {
        ...state,
        showAddBenefit: false,
      };
    case "SET_CAR_DETAILS_VALUES":
      return {
        ...state,
        carDetailsValues: action.payload,
      };
    case "SET_AGENT_CODE":
      return {
        ...state,
        quote: { ...state.quote, agentCode: action.payload }
      };
    case "SET_ADDITIONAL_VALUES":
      return {
        ...state,
        additionalValues: action.payload,
      };
    case "SELECTED_ADDITIONAL_BENEFITS":
      return {
        ...state,
        quote: { ...state.quote, additionalBenefits: action.payload },
        selectedAdditionalBenefits: map(action.payload),
      };
    case "FETCH_QUOTES_LOADING":
    case "START_QUOTE_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "START_QUOTE_SUCCESS":
      return {
        ...state,
        loading: false,
        quote: action.payload.data,
      };
    case "ADD_TO_CART_ITEM":
      return {
        ...state,
        cartTotal: 1,
        cart: action.payload,
      };
    case "FETCH_RISK_CLASSES_SUCCESS":
      return {
        ...state,
        riskClasses: action.payload,
        selectedRiskClass:
          (!isEmpty(action.payload) &&
            find(
              action.payload,
              (item) => item.classificationCategory === state.selectedVehicle
            )) ||
          null,
      };
    case "FETCH_COVER_TYPES_SUCCESS":
      return {
        ...state,
        coverTypes: action.payload,
      };
    case "FETCH_TONNAGE_RANGES_SUCCESS":
      return {
        ...state,
        tonnageRanges: action.payload,
      };
    case "FETCH_VEHICLE_TYPES_SUCCESS":
      return {
        ...state,
        vehicleTypes: action.payload,
      };
    case "FETCH_VEHICLE_MAKES_SUCCESS":
      return {
        ...state,
        vehicleMakes: action.payload,
      };
    case "FETCH_VEHICLE_MODELS_SUCCESS":
      return {
        ...state,
        vehicleModels: action.payload,
      };
    case "FETCH_QUOTES_SUCCESS":
      return {
        ...state,
        loading: false,
        quotes: filter(action.payload.data, (q) => q.underwriterId),
        quote: { ...state.quote, ...action.payload.quote, additionalBenefits: action.payload },
        selectVehicleValues: action.payload.formValues,
      };
    case "START_QUOTE_FAILURE":
    case "FETCH_QUOTES_FAILURE":
      return {
        ...state,
        loading: false,
      };

    case "FETCH_ADDITIONAL_BENEFITS_LOADING":
      return {
        ...state,
        benefitLoading: true,
        additionalBenefits: [],
      };
    case "FETCH_ADDITIONAL_BENEFITS_SUCCESS":
      return {
        ...state,
        benefitLoading: false,
        additionalBenefits: action.payload,
      };
    case "FETCH_ADDITIONAL_BENEFITS_FAILURE":
      return {
        ...state,
        benefitLoading: false,
        additionalBenefits: action.payload,
      };
    case "UPDATE_QUOTE_LOADING":
      return {
        ...state,
        updating: true,
      };
    case "UPDATE_QUOTE_SUCCESS":
      return {
        ...state,
        updating: false,
      };
    case "UPDATE_QUOTE_FAILURE":
      return {
        ...state,
        updating: false,
      };
    case "MAKE_PAYMENT_LOADING":
      return {
        ...state,
        paymentLoading: true,
        paymentSuccess: false,
        paymentFailure: false,
      };
    case "MAKE_PAYMENT_SUCCESS":
      return {
        ...state,
        paymentLoading: false,
        paymentError: false,
        paymentMessage: null,
        selectedStep: 6,
        payment: action.payload,
      };
    case "MAKE_PAYMENT_FAILURE":
      return {
        ...state,
        paymentLoading: false,
        paymentError: true,
        paymentMessage: action.payload.message,
        payment: {},
      };
    case "FETCH_ALL_UNDERWRITER_LOADING":
    case "FETCH_UNDERWRITER_LOADING":
      return {
        ...state,
      };
    case "FETCH_ALL_UNDERWRITER_SUCCESS":
      return {
        ...state,
        selectedUnderwriter: action.payload,
      };

    case "FETCH_ALL_UNDERWRITER_FAILURE":
      return {
        ...state,
        selectedUnderwriter: {},
      };
    case "FETCH_UNDERWRITER_SUCCESS":
      return {
        ...state,
        // quote: { ...state.quote,  underwriter: action.payload },
        underwriter: action.payload
      };
    case "FETCH__UNDERWRITER_FAILURE":
      return {
        ...state,
        quote: { ...state.quote, underwriter: action.payload },
      };
    case "SET_UNDERWRITER_PARAMS":
      return {
        ...state,
        underwriterName: action.payload,
      };
    default:
      return state;
  }
}
