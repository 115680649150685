import React from "react";
import PropTypes from "prop-types";
import BrandIcon from "../../../assets/svgs/underwriters";
import useStyles from "./styles";

const BrandLogo = ({ name }) => {
  const classes = useStyles();
  return (
    <div className={classes.brandContainer}>
      <div className={classes.brandContent}>
        <BrandIcon name={name} />
      </div>
    </div>
  );
};

BrandLogo.propTypes = {
  name: PropTypes.string,
};

BrandLogo.defaultProps = {
  name: "",
};

export default BrandLogo;
